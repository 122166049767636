button.btn-link {
    display: contents;
}

div.signup-card {
    min-width: 38.1967%;
    width: 576px;
    max-width: 90%;
}

div.referral-card {
    min-width: 38.1967%;
    width: 576px;
    max-width: 90%;
}

div.alerting-card {
    min-width: 38.1967%;
    width: 576px;
    max-width: 90%;
}
