svg[data-icon="ArbitrumOne"].brand-colour {
    color: #1b4add;
}

svg[data-icon="Avalanche"].brand-colour {
    color: #e84142;
}

svg[data-icon="Binance"].brand-colour {
    color: #f0b90b;
}

svg[data-icon="ethereum"].brand-colour {
    color: #12100b;
}

html[data-bs-theme="dark"] svg[data-icon="ethereum"].brand-colour {
    color: #716b94;
}

svg[data-icon="GLP"].brand-colour {
    color: #296de3;
}

svg[data-icon="OKX"].brand-colour {
    color: #000000;
}

html[data-bs-theme="dark"] svg[data-icon="OKX"].brand-colour {
    color: #FFFFFF;
}

svg[data-icon="Tether"].brand-colour {
    color: #50af95;
}
