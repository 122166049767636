.scroll-down-icon {
    position: absolute;
    left: 50%;
    top: 95%;
    margin-left: -0.5em;
    margin-top: -0.5em;
    opacity: 50%;
}

.scroll-up-icon {
    position: absolute;
    left: 50%;
    top: 5%;
    margin-left: -0.5em;
    margin-top: -0.5em;
    opacity: 50%;
    z-index: 1035;
}
