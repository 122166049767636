body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,h2,h3,h4,h5,h6 {
  margin: 0;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

hr {
  margin: 0;
}

input[type="checkbox"]:not([disabled]) {
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

input[data-lpignore] ~ div[data-lastpass-icon-root] {
  display: none !important;
}

/*
 * Note: Hack until react-toastify provide vertical centering. Until then, this
 * will result in vertically centering all toasts that have position: 'top-center'
 * https://github.com/fkhadra/react-toastify/issues/552#issuecomment-749217513
 */
.Toastify__toast-container--top-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.tooltip-wide.tooltip {
  width: 500px;
  max-width: 80vw;
}

.tooltip-wide > .tooltip-inner {
  max-width: 100%;
}

nav {
  height: 60px;
}

div.arcus-background {
  height: 100vh;
}

/** Scrollbar styling **/
/* From: https://www.digitalocean.com/community/tutorials/css-scrollbars#building-future-proof-scrollbar-styles */

/* Should work on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #313280 #222221;
}

/* Should work on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  background: #222221;
}

*::-webkit-scrollbar-thumb {
  background-color: #313280;
}

/** End: scrollbar styling **/

div.modal-dialog.modal-w-auto {
  width: fit-content;
}

.btn-xl, .btn-group-xl > .btn {
  --bs-btn-padding-y: 0.75rem;
  --bs-btn-padding-x: 1.5rem;
  --bs-btn-font-size: 1.625rem;
  --bs-btn-border-radius: 0.75rem;
}

.fa-2fw {
  width: 2.5em;
}
