.carouselWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 350px;
}

.carouselContent {
    width: 100%;
    height: 300px;
    border-radius: 8px;
    background-color: #212529;
    border: 1px solid #495057;
}

.carouselWrapper .carousel-control-prev {
    left: -40px;
    right: auto;
    width: auto;
}

.carouselWrapper .carousel-control-next {
    right: -40px;
    left: auto;
    width: auto;
}
