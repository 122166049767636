/*
 * Arrel branding colours:
 *   Primary light blue: C80 M10 Y45 K0 = R86 G160 B153 = #56a099
 *   Primary dark blue: C109 M95 Y5 K0 = R49 G50 B128 = #313280
 *   Off-black: C0 M0 Y0 K100 = R34 G34 B33 = #222221
 *   Off-white: C0 M0 Y0 K7 = R242 G242 B242 = #f2f2f2
 */

@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";

$custom-colors: (
        "primary": #56a099,
        "info": #313280,
        "light": #f2f2f2,
        "dark": #222221,
);

$theme-colors: map-merge($theme-colors, $custom-colors);

$body-bg: #222221;
$body-color: #f2f2f2;

$headings-font-weight: bold;

$font-family-sans-serif: "DINPro", #{inspect($font-family-sans-serif)};

$alert-padding-x: 0.75rem;
$alert-padding-y: 0.375rem;
$alert-margin-bottom: 0px;

h1,h2,h3 {
  font-family: "Expansiva", #{inspect($font-family-sans-serif)};
}

@import "~bootstrap/scss/bootstrap";
