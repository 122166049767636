html.snappy-scrolling {
    scroll-snap-type: y mandatory;
}

.transparent-bg {
    background: transparent !important;
}

main.landing-main {
    background: none;
    color: #f2f2f2;
}

section {
    position: relative;
    height: 100vh;
    scroll-snap-align: start;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
}

.gradient-background {
    background-image: radial-gradient(circle farthest-side at 0% 100%, #1ae2d3, #1a5d8c);
}

.gradient-background-inverted {
    background-image: radial-gradient(circle farthest-side at 0% 0%, #1ae2d3, #1a5d8c);
}

#infographic ul {
    margin: 0;
}

#infographic li {
    margin-top: 10px;
}

.landing-title-column {
    display: flex;
    flex-direction: column;
    width: 500px;
    text-align: center;
    align-items: center;
}

#infographic div.container {
    max-width: 90%;
}

div.title-container {
    width: 350px;
    text-align: center;
}

.title-container img {
    max-width: 100%;
    margin-top: 8vh;
    max-height: 70vh;
}

.infographic-container img {
    width: 100%;
    object-fit: contain;
}

.signup-button {
    width: 70%;
}

.youtube-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Style the iframe to fit in the youtube-container div with full height and width */
.responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}
