.ajax-form {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.ajax-form-footer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    margin-top: 1rem;
}

.ajax-form-footer div.alert {
    flex-grow: 1;
    max-width: 100%;
}
