#electronesLottieDiv {
    height: 200px;
    width: 100%;
}

.bottomText{
    padding-top: 0px;
}

.btnContainer {

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: calc(50% - 60px);
    z-index: 500;

}

.StopStartBtn:hover {
    cursor: pointer;
}

.StopStartBtn.disabledBtn:hover {
    cursor: default;
}

.StopStartBtn {

    margin: 40px 0px;

    height: 120px;
    width: 120px;
    background-color: transparent;
    border-radius: 60px;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    transition: all 0.5s;

}

.StopStartBtn hr {

    width: 60px;
    border: 2px solid #3E888A;
    border-radius: 8px;
    opacity: 10;
    transition: all 0.5s;

}

.StopStartBtn span {
    font-size: 0.8em;
    transition: all 0.5s;
}

span.BtnActiveText {
    font-size: 1.6em;
}

.StopStartBtn hr.disabledHr {
    border: 2px solid #3E888A;
}

.StopStartBtn hr.hedgingOffHr {
    border: 2px solid #00CCCC;
}   

.StopStartBtn hr.hedgingOnHr {
    border: 2px solid #15FEFF;
}

.StopStartBtn.disabledBtn {

    color: #ac9c9c;
    background-color: #3E3F40;
    border: 2px solid #3E9899;
    box-shadow: 0px 0px 0px 10px #3E3F40, 0px 0px 0px 15px #47ADAE, 0px 0px 0px 20px #448E90;

}

.StopStartBtn.hedgingOffBtn {

    color: white;
    background-color: black;
    border: 2px solid #00C9C9;
    box-shadow: 0px 0px 0px 10px black, 0px 0px 0px 15px #47ADAE, 0px 0px 0px 20px #448E90;

}

.StopStartBtn.hedgingOnBtn {

    color: white;
    background-color: black;
    border: 2px solid #11E6F0;
    box-shadow: 0px 0px 0px 10px black, 
        0px 0px 0px 15px #15E1F3, 
        0px 0px 2px 20px #1B6F7B, 
        0px 0px 40px 20px #7AB3BA, 
        0px 0px 30px 50px black

}

@media screen and (max-width: 1024px) {

    #electronesLottieDiv {

        position: relative;
        min-width: 1024px;
        left: calc(calc(100vw - 1032px)/2);

    }

}
