footer {
    font-size: calc(8px + 0.4vw);
    color: #f2f2f2;
    scroll-snap-align: start;
}

footer a {
    text-decoration: none;
    color: inherit;
}

footer a:hover {
    color: #56a099;
}

.footer-container {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    text-align: center;
    font-size: calc(8px + 0.4vw);
}

.footer-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 33%;
}

.footer-column h4 {
    margin-bottom: 1rem;
    font-size: calc(9px + 0.4vw); /* Necessary to override bootstrap */
}

.footer-column ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.version {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.version p {
    margin: 0.25rem;
    align-items: center;
    font-size: 0.75rem;
    opacity: .7;
}

.powered-by {
    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: black;
}

.powered-by p {
    margin: 0;
    max-height: 70%;
    align-items: center;
}

.powered-by a {
    max-height: 100%;
}

.powered-by img {
    max-height: 100%;
    object-fit: contain;
}
