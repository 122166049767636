@font-face {
    font-family: 'DINPro';
    src: url('DINPro-Bold.woff2') format('woff2'),
        url('DINPro-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DINPro';
    src: url('DINPro-Regular.woff2') format('woff2'),
        url('DINPro-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DINPro';
    src: url('DINPro-Medium.woff2') format('woff2'),
        url('DINPro-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DINPro';
    src: url('DINPro-Light.woff2') format('woff2'),
        url('DINPro-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DINPro';
    src: url('DINPro-Black.woff2') format('woff2'),
        url('DINPro-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Expansiva';
    src: url('Expansiva-Italic.woff2') format('woff2'),
        url('Expansiva-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Expansiva';
    src: url('Expansiva.woff2') format('woff2'),
        url('Expansiva.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Expansiva';
    src: url('Expansiva-Bold.woff2') format('woff2'),
        url('Expansiva-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Expansiva';
    src: url('Expansiva-BoldItalic.woff2') format('woff2'),
        url('Expansiva-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}
